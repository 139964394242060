<template>
  <div class="flex flex-col items-start gap-3">
    <div
      v-for="option in options"
      :key="option.value"
      class="flex gap-2 cursor-pointer"
    >
      <input
        :id="`${item.macId}-${option.value}`"
        v-model="radioButtonValue"
        type="radio"
        :name="`group-${item.macId}`"
        :value="option.value"
        class="cursor-pointer"
      />
      <label :for="`${item.macId}-${option.value}`" class="cursor-pointer">{{
        option.value
      }}</label>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeMount } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(['input']);

const radioButtonValue = ref('');

const options = computed(() => {
  if (props.item.options) {
    return JSON.parse(props.item.options);
  }
  return [{ value: 'Да' }, { value: 'Нет' }];
});

watch(radioButtonValue, () => {
  emit('input', { macId: props.item.macId, value: radioButtonValue.value });
});

onBeforeMount(() => {
  if (props.item?.pollAnswer) radioButtonValue.value = props.item.pollAnswer;
});
</script>
