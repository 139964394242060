export const CONTROL_TYPES = {
  0: 'b-empty',
  1: 'b-radio-buttons',
  2: 'b-empty',
  3: 'b-text-area',
  4: 'b-empty',
  5: 'b-select',
  6: 'b-input',
  7: 'b-rating-scale',
};

export const CONTROL_SUBTYPES = {
  TEXT: 'text',
  DATE: 'date',
  TEL: 'text',
  EMAIL: 'email',
  NUMBER: 'number',
};
