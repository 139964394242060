<template>
  <div class="flex gap-1 justify-between b-transition__all">
    <button v-for="star in max" :key="star" @click="handleSelectRating(star)">
      <BIcon
        class="b-transition__all"
        name="star"
        size="xl"
        :view="'no-style'"
        :class="star > rating ? 'fill-core-gray-500' : 'fill-core-warning'"
      />
    </button>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(['input']);

const rating = ref(0);
const max = ref(5);

const handleSelectRating = (star) => {
  rating.value = star;
  emit('input', { macId: props.item.macId, value: star });
};

onBeforeMount(() => {
  if (props.item?.options) max.value = +props.item.options;
  if (props.item?.pollAnswer) rating.value = props.item.pollAnswer;
});
</script>
