import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from './plugins/axios';
import { createPinia } from 'pinia';

import './assets/tailwind.css';

import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

const pinia = createPinia();
const app = createApp(App);

const requireComponent = require.context(
  '@/components/ui',
  false,
  /B[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  );

  app.component(componentName, componentConfig.default || componentConfig);
});

app.use(axios, {
  baseUrl: process.env.VUE_APP_API_URL,
});
app.use(pinia);

app.use(router).mount('#app');
