<template>
  <div class="b-spinner-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<style>
.b-spinner-ellipsis {
  @apply inline-block relative h-3 w-20;
}
.b-spinner-ellipsis div {
  @apply absolute w-3 h-3 rounded-full bg-accent-1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.b-spinner-ellipsis div:nth-child(1) {
  left: 8px;
  animation: b-spinner-ellipsis1 0.6s infinite;
}
.b-spinner-ellipsis div:nth-child(2) {
  left: 8px;
  animation: b-spinner-ellipsis2 0.6s infinite;
}
.b-spinner-ellipsis div:nth-child(3) {
  left: 32px;
  animation: b-spinner-ellipsis2 0.6s infinite;
}
.b-spinner-ellipsis div:nth-child(4) {
  left: 56px;
  animation: b-spinner-ellipsis3 0.6s infinite;
}
@keyframes b-spinner-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes b-spinner-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes b-spinner-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
