<template>
  <div>
    <textarea
      v-model="textBody"
      class="resize-none w-full rounded-lg bg-core-bg outline-0 p-2"
      rows="3"
      @input="emitData"
    ></textarea>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(['input']);

const textBody = ref('');

const emitData = () => {
  emit('input', { macId: props.item.macId, value: textBody.value });
};

onBeforeMount(() => {
  if (props.item?.pollAnswer) textBody.value = props.item.pollAnswer || '';
});
</script>
