<template>
  <div class="scrollbox" @scroll="handleScroll">
    <slot />
  </div>
</template>

<script setup>
const handleScroll = (e) => {
  this.$emit('is-scroll', e.target);
};
</script>

<style lang="scss">
.scrollbox {
  overflow-y: scroll;
  // padding-right: 10px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: gray;
  }
}
</style>
