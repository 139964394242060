<template>
  <div :class="viewClasses[viewType]">
    <slot />
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  viewType: {
    type: Number,
    default: 1,
    validator(value) {
      return [1, 2].includes(value);
    },
  },
});
const viewClasses = ref({
  1: 'bg-core-light rounded-[20px] p-8 drop-shadow-[0_0_4px_rgba(0,0,0,0.1)] shadow-[0_0_8px_rgba(0,0,0,0.1)] w-[454px]',
  2: 'bg-core-light rounded-xl p-4 w-[454px]',
});
</script>
