<template>
  <svg
    :class="[iconView[view], iconSize[size]]"
    xmlns="http://www.w3.org/2000/svg"
    @click="emit('click')"
  >
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  name: { type: String, default: 'no-symbol' },
  title: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: 'md',
    validator(value) {
      return ['sm', 'md', 'xl', 'custom'].includes(value);
    },
  },
  view: {
    type: String,
    default: 'outline',
    validator(value) {
      return [
        'no-style',
        'outline',
        'outline-light',
        'outline-gray',
        'outline-dark',
        'fill',
        'fill-gray',
        'fill-warning',
        'fill-dark',
      ].includes(value);
    },
  },
});

const emit = defineEmits(['click']);

const iconSize = ref({
  sm: 'w-3 h-3',
  md: 'w-4.5 h-4.5',
  xl: 'w-8 h-8',
  custom: '',
});

const iconView = ref({
  outline: 'outline-default',
  'outline-light': 'outline-light',
  'outline-gray': 'outline-gray',
  'outline-dark': 'outline-dark',
  fill: 'fill-default',
  'no-style': '',
  'fill-gray': 'fill-gray',
  'fill-warning': 'fill-warning',
  'fill-dark': 'fill-dark',
});

const iconPath = computed(() => {
  let icon = require(`@/assets/svg/${props.name}.svg`);
  if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
    icon = icon.default;
  }

  return icon.url;
});
</script>

<style>
.outline-default {
  @apply stroke-accent-main;
}
.outline-light {
  @apply stroke-core-light;
}
.outline-gray {
  @apply stroke-core-outline;
}
.outline-dark {
  @apply stroke-core-dark;
}
.fill-default {
  @apply fill-accent-main;
}
.fill-gray {
  @apply fill-core-outline;
}
.fill-warning {
  @apply fill-core-warning;
}
.fill-dark {
  @apply fill-core-dark;
}
</style>
