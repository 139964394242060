<template>
  <button
    class="flex justify-center items-center gap-2 b-transition__all"
    :class="[viewBtn[view], isActive, sizeBtn[size]]"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <template v-if="icon"><BIcon :name="icon" /></template>
    <template v-if="title">{{ title }}</template>
  </button>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  title: {
    default: '',
    type: String,
  },
  icon: {
    default: '',
    type: String,
  },
  view: {
    default: 'outline',
    type: String,
    validator(value) {
      return ['none', 'outline', 'fill', 'disabled'].includes(value);
    },
  },
  size: {
    default: 'md',
    type: String,
    validator(value) {
      return ['none', 'md', 'lg'].includes(value);
    },
  },
  color: {
    default: 'accent',
    type: String,
    validator(value) {
      return ['accent', 'accent-1'].includes(value);
    },
  },
  disabled: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['click']);

const viewBtn = ref({
  outline: 'b-button__outline',
  fill: 'b-button__fill',
  none: '',
  disabled: 'default-btn--disabled',
});

const sizeBtn = ref({
  none: '',
  md: 'px-6 py-3 sm:py-2.5',
  lg: 'px-8 py-3 sm:py-2.5',
});
const colorBtn = ref({
  accent: '--accent',
  'accent-1': '--accent-1',
});

const isActive = computed(() => {
  if (props.disabled) {
    return `${viewBtn.value[props.view]}--disabled`;
  }
  return `${viewBtn.value[props.view]}${colorBtn.value[props.color]}`;
});
</script>

<style lang="scss">
.b-button {
  @apply text-base sm:text-sm leading-none font-gilroy-medium rounded-full;

  &__outline {
    @extend .b-button;

    &--accent {
      @apply text-accent-main bg-core-light border border-accent-main;
      @apply hover:bg-accent-main hover:text-core-light [&>svg]:hover:stroke-core-light;

      &-1 {
        @apply text-accent-1 bg-core-light border border-accent-1;
        @apply hover:bg-accent-1 hover:text-core-light [&>svg]:hover:stroke-core-light;
      }
    }
  }

  &__fill {
    @extend .b-button;

    &--accent {
      @apply text-core-light bg-accent-main;
      &-1 {
        @apply text-core-light bg-accent-1;
      }
    }
    &--disabled {
      @apply text-core-light bg-core-gray-500;
    }
  }
}
</style>
