<template>
  <div class="flex flex-col">
    <div
      class="flex items-center border border-core-bg rounded-lg p-2"
      @click="toggleOptions"
    >
      <input
        v-model="selectValue"
        type="text"
        class="w-full outline-0 cursor-pointer"
        readonly
      />
      <BIcon
        name="arrow_down"
        class="cursor-pointer transition-transform"
        :class="{ 'rotate-180': isOpenOptions }"
      />
    </div>
    <div
      v-if="isOpenOptions"
      class="w-full border border-core-bg rounded-lg p-2 pr-1 bg-core-light"
    >
      <b-scroll-box v-if="options.length" class="max-h-60 flex flex-col gap-2">
        <div
          v-for="(option, idx) in options"
          :key="idx"
          class="cursor-pointer"
          @click="handleSetValue(option.value)"
        >
          {{ option.value }}
        </div>
      </b-scroll-box>
      <div v-else class="w-full p-6">Опции отсутствуют</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {};
    },
  },
});

const emit = defineEmits(['input']);

const selectValue = ref('');
const isOpenOptions = ref(false);

const options = computed(() => {
  if (props.item.options?.length && typeof props.item.options === 'string') {
    return JSON.parse(props.item.options);
  }
  if (props.item.options?.length && Array.isArray(props.item.options)) {
    return props.item.options;
  }
  return [];
});

const toggleOptions = () => {
  isOpenOptions.value = !isOpenOptions.value;
};
const handleSetValue = (option) => {
  selectValue.value = option;
  toggleOptions();
  emit('input', { macId: props.item.macId, value: option });
};

onBeforeMount(() => {
  if (props.item?.pollAnswer) selectValue.value = props.item.pollAnswer;
});
</script>
